import React, { useEffect, useState } from 'react'
import "../assets/styles/service.css"
import { IoLocationOutline } from 'react-icons/io5'
import { FaPhoneAlt } from 'react-icons/fa'
import { IoMdMail } from 'react-icons/io'
import pic1 from "../assets/images/pic1.jpg"
import pic2 from "../assets/images/pic2.jpg"
import pic3 from "../assets/images/pic3.jpg"
import { Autoplay, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react';
import Navbar from '../layout/Navbar'
import ResponsiveNavbar from '../layout/ResponsiveNavbar'
import { FiPlus } from 'react-icons/fi'
import brickwork_1 from "../assets/images/projects/brickwork_1.webp"
import brickwork_2 from "../assets/images/projects/brickwork_2.webp"
import brickwork_3 from "../assets/images/projects/brickwork_3.webp"
import { Link } from 'react-router-dom'
import FixedFooter from '../layout/FixedFooter'

const Brickwork = () => {

    const [openPanel, setOpenPanel] = useState('collapseOne5');

    const togglePanel = (panelId) => {
        setOpenPanel(openPanel === panelId ? '' : panelId);
    };

    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    return (
        <>
            <Navbar />
            <ResponsiveNavbar />
            <div class="service_sec_brickworks">
                <div class="overlay-main_service "></div>
                <div class="container">
                    <div class="wt-bnr-inr-entry">
                        <h1>Brickwork Services</h1>
                    </div>
                </div>
            </div>

            <div className="about_sec p-t80 p-b50">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-sm-12">

                            <div class="wt-nav-block m-b30">
                                <h2 class="wt-nav-title">What we do</h2>
                                <ul class="wt-nav wt-nav-pills">
                                    <li><Link to="/service-detail/bedroom-kitchen-fitting" >Bedroom & Kitchen fitting</Link></li>
                                    <li><Link to="/service-detail/brickwork-services">Brickwork Services</Link></li>
                                    <li><Link to="/service-detail/home-renovation">Home Renovation</Link></li>
                                    <li><Link to="/service-detail/home-extension">House Extension</Link></li>
                                    <li><Link to="/service-detail/loft-conversion">Loft Conversion</Link></li>
                                    <li><Link to="/service-detail/new-builds">New Builds</Link></li>
                                </ul>
                            </div>

                            <div className="widget widget_getintuch">
                                <h4 class="widget-title" style={{ color: "#000" }}>Contact us</h4>

                                <ul>
                                    <li><IoLocationOutline style={{ color: "#fff" }} /><strong>Address</strong> <p>8 Trevor road HA80eu ,Edgware</p></li>
                                    <li><FaPhoneAlt style={{ color: "#fff" }} /><strong>phone</strong>
                                        <p>+447928470998</p>
                                    </li>
                                    <li><IoMdMail style={{ color: "#fff" }} /><strong>email</strong>
                                        <p>info@sajinaconstruction.co.uk</p>
                                    </li>
                                </ul>
                            </div>


                            <div className="contact__page_card  fixed_form" style={{ margin: "0px" }}>
                                <h4>Enquiry Form</h4>

                                <div class="p-a30 bg-gray">

                                    <form class="cons-contact-form">

                                        <div class="row">

                                            <div class="col-md-12 col-lg-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <span class="input-group-addon"><i class="fa fa-user"></i></span>
                                                        <input name="username" type="text" required="" class="form-control" placeholder="Name" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-12 col-lg-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <span class="input-group-addon"><i class="fa fa-envelope"></i></span>
                                                        <input name="email" type="text" class="form-control" required="" placeholder="Email" />
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="col-md-12 col-lg-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <span class="input-group-addon v-align-t"><i class="fa fa-pencil"></i></span>
                                                        <textarea name="message" rows="1" class="form-control " required="" placeholder="Message"></textarea>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-12 col-lg-12">
                                                <button name="submit" type="submit" value="Submit" class="site-button  m-r15">Submit  <i class="fa fa-angle-double-right"></i></button>
                                                <button name="Resat" type="reset" value="Reset" class="site-button ">Reset  <i class="fa fa-angle-double-right"></i></button>
                                            </div>

                                        </div>

                                    </form>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 col-sm-12">
                            <div className="services_sect_card">
                                <Swiper
                                    spaceBetween={30}
                                    centeredSlides={true}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    navigation={true}
                                    modules={[Autoplay, Pagination, Navigation]}
                                    className="mySwiper"
                                >
                                    <SwiperSlide>
                                        <img src={brickwork_1} alt="" style={{ width: "100%" }} />
                                    </SwiperSlide>
                                    <SwiperSlide>  <img src={brickwork_2} alt="" style={{ width: "100%" }} /></SwiperSlide>
                                    <SwiperSlide>  <img src={brickwork_3} alt="" style={{ width: "100%" }} /></SwiperSlide>

                                </Swiper>
                            </div>
                            <div class="wt-box">
                                <h2>Brickwork Services</h2>
                                <p>
                                    At Sajina Constructions, our brickwork services are designed to provide robust and aesthetically pleasing structures for various construction needs. Whether you're building a new home, renovating an existing structure, or adding architectural features, our expert brickwork team ensures precision and quality in every project.
                                </p>

                                <p>
                                    Custom Brickwork: We offer tailored solutions to match your design preferences and structural requirements. From traditional to contemporary styles, our team handles everything from laying bricks to creating intricate patterns and finishes. We work closely with you to achieve the desired look and functionality for your project.
                                </p>
                            </div>

                            <div class="wt-box">
                                <div class="p-t30">
                                    <div class="section-head">

                                        <h4 class="widget-title" style={{ color: "#000" }}>What is included</h4>
                                    </div>

                                    <div class="section-content p-b30">
                                        <div id="accordion5">
                                            <div className="panel wt-panel">
                                                <div className="acod-head acc-actives">
                                                    <h6 className="acod-title text-uppercase">
                                                        <a
                                                            href="javascript:;"
                                                            onClick={() => togglePanel('collapseOne5')}
                                                        >
                                                            <i className="fa fa-globe"></i> Custom Bricklaying
                                                            <span className="indicator">
                                                                <FiPlus />
                                                            </span>
                                                        </a>
                                                    </h6>
                                                </div>
                                                <div
                                                    id="collapseOne5"
                                                    className={`acod-body collapse ${openPanel === 'collapseOne5' ? 'show' : ''}`}
                                                >
                                                    <div className="acod-content p-tb15">
                                                        Tailored designs for walls, facades, and architectural features, including intricate patterns and unique finishes to match your vision.
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="panel wt-panel">
                                                <div className="acod-head">
                                                    <h6 className="acod-title text-uppercase">
                                                        <a
                                                            href="javascript:;"
                                                            onClick={() => togglePanel('collapseTwo5')}
                                                            className={openPanel === 'collapseTwo5' ? '' : 'collapsed'}
                                                        >
                                                            <i className="fa fa-photo"></i> Quality Material Selection
                                                            <span className="indicator">
                                                                <FiPlus />
                                                            </span>
                                                        </a>
                                                    </h6>
                                                </div>
                                                <div
                                                    id="collapseTwo5"
                                                    className={`acod-body collapse ${openPanel === 'collapseTwo5' ? 'show' : ''}`}
                                                >
                                                    <div className="acod-content p-tb15">
                                                        Use of high-grade bricks and mortar, chosen for their durability and aesthetic appeal, ensuring long-lasting and structurally sound results.
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="panel wt-panel">
                                                <div className="acod-head">
                                                    <h6 className="acod-title text-uppercase">
                                                        <a
                                                            href="javascript:;"
                                                            onClick={() => togglePanel('collapseThree5')}
                                                            className={openPanel === 'collapseThree5' ? '' : 'collapsed'}
                                                        >
                                                            <i className="fa fa-cog"></i> Professional Installation
                                                            <span className="indicator">
                                                                <FiPlus />
                                                            </span>
                                                        </a>
                                                    </h6>
                                                </div>
                                                <div
                                                    id="collapseThree5"
                                                    className={`acod-body collapse ${openPanel === 'collapseThree5' ? 'show' : ''}`}
                                                >
                                                    <div className="acod-content p-tb15">
                                                        Expert craftsmanship from experienced bricklayers, focusing on precise alignment, even spacing, and meticulous attention to detail for a flawless finish.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

            <FixedFooter />

        </>
    )
}

export default Brickwork