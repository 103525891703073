import React, { useEffect } from 'react'
import Navbar from '../layout/Navbar'
import ResponsiveNavbar from '../layout/ResponsiveNavbar'
import "../assets/styles/about.css"
import abt from "../assets/images/abt.webp"
import t1 from "../assets/images/team/t1.jpg"
import t2 from "../assets/images/team/t2.jpg"
import t3 from "../assets/images/team/t3.jpg"
import t4 from "../assets/images/team/t4.jpg"
import logo3 from "../assets/images/logo3.png"
import logo4 from "../assets/images/logo4.png"
import { Swiper, SwiperSlide } from 'swiper/react';

const About = () => {


    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    return (
        <div>  <Navbar />
            <ResponsiveNavbar />

            <div className="about_banner">
                <div className="content">
                    <h1>About Us</h1>
                </div>
            </div>


            <div className="about_sec p-t80 p-b50">
                <div className="container">
                    <div class="section-head text-center" style={{ padding: "20px" }}>
                        <h2 class="text-uppercase">About Company</h2>

                        <p style={{ color: "rgb(175 155 155)" }}>Our company excels in delivering top-notch construction services, blending innovation with quality to create exceptional spaces and lasting client satisfaction.</p>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-sm-12">
                            <img src={abt} alt="" style={{ width: "100%", height: "500px", marginLeft: "30px" }} />
                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <div className="desc_sec" style={{ paddingLeft: "50px" }}>
                                <p style={{ marginRight: "20px" }}>Sajina Constructions is a leading construction company, renowned for delivering high-quality building solutions that cater to a wide range of needs. With years of experience in the industry, we have earned a reputation for excellence, reliability, and innovation. Our services span residential, commercial, and industrial projects, ensuring that we provide comprehensive solutions tailored to each client’s unique vision.
                                </p>

                                <p style={{ marginRight: "20px" }}>

                                    At Sajina Constructions, we believe in a customer-first approach. Our process begins with a deep understanding of your goals, followed by detailed planning and design to ensure every project meets your exact specifications. We utilize the latest construction techniques, advanced technologies, and high-quality materials to guarantee durability and aesthetic appeal in every build. From new builds and home extensions to renovations and loft conversions, we handle every aspect with precision and care.
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="section-full p-t50 p-b50 overlay-wraper bg-parallax" >
                <div class="overlay-main opacity-07 bg-black"></div>
                <div class="container ">
                    <div class="row d-flex justify-content-end">

                        <div class="col-lg-8 col-md-12">

                            <div class="text-right text-white">
                                <h3 class="font-24">The Construction Company</h3>
                                <h1 class="font-60">AWESOME FACTS</h1>
                                <p>We are a leading construction company dedicated to excellence, offering innovative solutions and superior craftsmanship. Our focus is on delivering exceptional projects on time and within budget.</p>
                            </div>

                            <div class="row" style={{ margin: "20px" }}>
                                <div class="col-lg-4 col-md-4">
                                    <div class="status-marks text-white m-b30">
                                        <div class="status-value text-right">
                                            <span class="counter">1150</span>
                                            <i class="fa fa-building font-26 m-l15"></i>
                                        </div>
                                        <h6 class="text-uppercase text-white text-right">PROJECT COMPLETED</h6>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="status-marks  text-white m-b30">
                                        <div class="status-value text-right">
                                            <span class="counter">5223</span>
                                            <i class="fa fa-users font-26 m-l15"></i>
                                        </div>
                                        <h6 class="text-uppercase text-white text-right">HAPPY CLIENTS</h6>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="status-marks  text-white m-b30">
                                        <div class="status-value text-right">
                                            <span class="counter">4522</span>
                                            <i class="fa fa-user-plus font-26 m-l15"></i>
                                        </div>
                                        <h6 class="text-uppercase text-white text-right">WORKERS EMPLOYED</h6>
                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>
                </div>
            </div>


            <div className="p-t80 p-b50">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-sm-12">
                            <h2 className="text-center">Our team</h2>
                            <p className="text-center" style={{ color: "gray" }}>Our team consists of skilled professionals dedicated to delivering excellence, innovation, and exceptional results on every project.</p>
                        </div>

                        <div className="row">
                            <div className="col-lg-3 col-sm-12">
                                <div className="team_card">
                                    <img src={t1} alt="" style={{ width: "100%" }} />
                                    <div className="team_card_desc">
                                        <h4>Ramesh</h4>
                                        <p>Building Worker</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-12">
                                <div className="team_card">
                                    <img src={t2} alt="" style={{ width: "100%" }} />
                                    <div className="team_card_desc">
                                        <h4>Sanjay</h4>
                                        <p>Building Worker</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-12">
                                <div className="team_card">
                                    <img src={t3} alt="" style={{ width: "100%" }} />
                                    <div className="team_card_desc">
                                        <h4>Riya</h4>
                                        <p>Building Worker</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-12">
                                <div className="team_card">
                                    <img src={t4} alt="" style={{ width: "100%" }} />
                                    <div className="team_card_desc">
                                        <h4>Samay</h4>
                                        <p>Building Worker</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="p-t80 p-b50">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-sm-12">
                            <h2 className="text-center">Our Clients</h2>
                            <p className="text-center" style={{ color: "gray" }}>Our clients are valued partners, benefiting from our commitment to quality, reliability, and tailored solutions for their needs.

                            </p>
                        </div>


                    </div>

                    <div className="row">
                        <Swiper
                            slidesPerView={4}
                            spaceBetween={30}
                            pagination={{
                                clickable: true,
                            }}
                            className="mySwiper d-flex align-items-center"
                        >
                            <SwiperSlide><img src={logo3} alt="" /></SwiperSlide>
                            <SwiperSlide><img src={logo4} alt="" /></SwiperSlide>
                            <SwiperSlide><img src={logo3} alt="" /></SwiperSlide>
                            <SwiperSlide><img src={logo4} alt="" /></SwiperSlide>
                            <SwiperSlide><img src={logo3} alt="" /></SwiperSlide>

                        </Swiper>
                    </div>
                </div>
            </div>

        </div >
    )
}

export default About