
import { TbHomeCheck } from "react-icons/tb"
import "../assets/styles/home.css"
import Navbar from "../layout/Navbar"
import ResponsiveNavbar from "../layout/ResponsiveNavbar"
import { RiTimerLine } from "react-icons/ri"
import { FaBuilding, FaPaintBrush, FaTrophy } from "react-icons/fa"
import { FaPeopleGroup, FaPersonDigging } from "react-icons/fa6"
import { AiFillLike, AiOutlinePicture, AiOutlineSafety } from "react-icons/ai"
import side from "../assets/images/side.webp"
import g1 from "../assets/images/gallery/g1.webp"
import g2 from "../assets/images/gallery/g2.webp"
import g3 from "../assets/images/gallery/g3.webp"
import g4 from "../assets/images/gallery/g4.webp"
import { IoIosFootball } from "react-icons/io"
import t1 from "../assets/images/team/t1.jpg"
import t2 from "../assets/images/team/t2.jpg"
import t3 from "../assets/images/team/t3.jpg"
import t4 from "../assets/images/team/t4.jpg"
import { Swiper, SwiperSlide } from 'swiper/react';
import logo3 from "../assets/images/logo3.png"
import logo4 from "../assets/images/logo4.png"
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import kitchen from "../assets/images/icons/kitchen.png"
import build from "../assets/images/icons/build.png"
import extension from "../assets/images/icons/extension.png"
import brickwork from "../assets/images/icons/brickwork.png"
import loft from "../assets/images/icons/loft.png"
import renovation from "../assets/images/icons/renovation.png"

// import required modules
import { Navigation, Pagination } from 'swiper/modules';
import { CiSettings } from "react-icons/ci"
import { MdPeopleAlt } from "react-icons/md"
import { ImUserPlus } from "react-icons/im"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"

const Home = () => {


    const [showContactModal, setShowContactModal] = useState(false)

    useEffect(() => {
        window.scroll(0, 0)
    }, [])




    return (

        <>
            <Navbar />
            <ResponsiveNavbar />
            <div className="main_slider">
                <div className="container">



                    <div className="row">
                        <div className="col-lg-8 col-sm-12">
                            <div className="content">
                                <h1>We Build Your  <span> Dream Office
                                </span></h1>
                                <p>Transform your workspace into a hub of productivity and creativity. At Sajina Constructions, we specialize in designing office spaces that inspire success. Whether you need a modern, sleek environment or a cozy, collaborative space, we’ve got you covered.</p>
                                <button className="home_btn">READ MORE</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <section className="section-full site-bg-primary">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-8 col-sm-12">
                            <div className="row">
                                <div className="col-lg-6 col-sm-12">
                                    <div class="wt-icon-box-wraper left ">
                                        <div class="icon-md">
                                            <div class="icon-cell text-white">
                                                <span class="iconmoon-buildings"><TbHomeCheck fontSize="30px" color="#fff" /></span>
                                            </div>
                                        </div>
                                        <div class="icon-content text-secondry">
                                            <h5 class="wt-tilte text-uppercase m-b5">Best Quality</h5>
                                            <p>We deliver top-quality, innovative office designs that maximize productivity, comfort, and style within any budget.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-12">
                                    <div class="wt-icon-box-wraper left ">
                                        <div class="icon-md">
                                            <div class="icon-cell text-white">
                                                <span class="iconmoon-buildings"><RiTimerLine fontSize="30px" color="#fff" /> </span>
                                            </div>
                                        </div>
                                        <div class="icon-content text-secondry">
                                            <h5 class="wt-tilte text-uppercase m-b5">24 hour support</h5>
                                            <p>We provide reliable 24/7 support, ensuring your office needs are met anytime, day or night.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12" >
                            <div class="pull-right" onClick={() => setShowContactModal(true)}>
                                <a class="site-button-secondry text-uppercase font-weight-600" >Contact us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <div class="section-full p-t80 p-b50">
                <div class="container">
                    <div class="section-head text-center">
                        <h2 class="text-uppercase">About Company </h2>

                        <p>Sajina Constructions specializes in high-quality building solutions, offering innovative designs, expert craftsmanship, and exceptional service for every project.</p>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <div class="about-types row">
                                <div class="col-md-6 col-sm-12 m-b30">
                                    <div class="wt-icon-box-wraper left">
                                        <div class="icon-sm site-text-primary">
                                            <a href="#" class="icon-cell p-t5 center-block"><FaBuilding fontSize="30px" /></a>
                                        </div>
                                        <div class="icon-content">
                                            <h5 class="text-uppercase m-b0">Building</h5>
                                            <p>We construct durable, modern buildings with precision, quality, and care.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12 m-b30 ">
                                    <div class="wt-icon-box-wraper left">
                                        <div class="icon-sm site-text-primary">
                                            <a href="#" class="icon-cell p-t5 center-block"><FaPaintBrush fontSize="30px" /></a>
                                        </div>
                                        <div class="icon-content">
                                            <h5 class=" text-uppercase m-b0">Renovation</h5>
                                            <p>We transform spaces through expert renovations, enhancing functionality, style, and value.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12 m-b30">
                                    <div class="wt-icon-box-wraper left">
                                        <div class="icon-sm site-text-primary">
                                            <a href="#" class="icon-cell p-t5 center-block"><FaPersonDigging fontSize="30px" /></a>
                                        </div>
                                        <div class="icon-content">
                                            <h5 class="text-uppercase m-b0 ">Digging</h5>
                                            <p>We provide precise, efficient digging services for foundations and excavation projects.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12 m-b30">
                                    <div class="wt-icon-box-wraper left">
                                        <div class="icon-sm site-text-primary">
                                            <a href="#" class="icon-cell p-t5 center-block"><AiOutlinePicture fontSize="30px" /></a>
                                        </div>
                                        <div class="icon-content">
                                            <h5 class="text-uppercase m-b0">Interior</h5>
                                            <p>We craft elegant, functional interiors tailored to your style and needs.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section-content">
                                <div class="row">

                                    <div class="col-md-4 col-sm-12 m-b30">
                                        <div class="p-a10 m-b10 wt-box text-center bdr-1 bdr-primary text-secondry">
                                            <div class="icon-sm m-b10" ><FaBuilding fontSize="20px" /></div>
                                            <div class="counter font-16 font-weight-700 m-b5">2500</div>
                                            <span class="text-uppercase">PROJECT COM.</span>
                                        </div>
                                    </div>

                                    <div class="col-md-4 col-sm-12 m-b30">
                                        <div class="p-a10 m-b10 wt-box text-center bdr-1 bdr-primary text-secondry">
                                            <div class="icon-sm m-b10"><MdPeopleAlt fontSize="20px" /></div>
                                            <div class="font-16 font-weight-700 m-b5"><span class="counter">1500</span><b>+</b></div>
                                            <span class="text-uppercase">HAPPY CLIENTS</span>
                                        </div>
                                    </div>

                                    <div class="col-md-4 col-sm-12 m-b30">

                                        <div class="p-a10 m-b10 wt-box text-center bdr-1 bdr-primary text-secondry">
                                            <div class="icon-sm m-b10"><ImUserPlus fontSize="20px" /></div>

                                            <div class="counter font-16 font-weight-700 m-b5">4500</div>
                                            <span class="text-uppercase">WORKERS</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 m-b30">
                            <div class="wt-media">
                                <img src={side} alt="" class="img-responsive" style={{ width: "100%" }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="p-t80 p-b50 section-full bg-gray">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-sm-12">
                            <h2 className="text-center">Our Services</h2>
                            <p className="text-center" style={{ color: "gray" }}>Choose us for exceptional craftsmanship, innovative designs, timely delivery, and a commitment to exceeding your construction expectations.</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-sm-12">

                            <div class="spec_icon p-a20 left bg-white ">
                                <img src={kitchen} alt="" />

                                <div class="icon-content">
                                    <h5 class="text-uppercase">Bedroom & Kitchen Fitting</h5>
                                    <p>We ensure the best quality through superior materials, expert craftsmanship, and rigorous standards, delivering excellence in every project.</p>
                                </div>
                                <Link to="/service-detail/bedroom-kitchen-fitting" style={{ marginTop: "5px" }}>
                                    <button name="submit" type="submit" value="Submit" class="site-button  m-r15">Know More</button>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">

                            <div class="spec_icon p-a20 left bg-white ">
                                <img src={brickwork} alt="" />
                                <div class="icon-content">
                                    <h5 class="text-uppercase">Brickwork Services</h5>
                                    <p>We uphold integrity by being transparent, honest, and reliable in all our dealings, building trust with every client and project.</p>
                                </div>
                                <Link to="/service-detail/brickwork-services" style={{ marginTop: "5px" }}>
                                    <button name="submit" type="submit" value="Submit" class="site-button  m-r15">Know More</button>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div class="spec_icon p-a20 left bg-white ">
                                <img src={renovation} alt="" />
                                <div class="icon-content">
                                    <h5 class="text-uppercase">Home Renovation</h5>
                                    <p>Our strategy focuses on meticulous planning, innovative solutions, and effective execution to ensure successful project outcomes and client satisfaction.</p>
                                </div>
                                <Link to="/service-detail/home-renovation" style={{ marginTop: "5px" }}>
                                    <button name="submit" type="submit" value="Submit" class="site-button  m-r15">Know More</button>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div class="spec_icon p-a20 left bg-white ">
                                <img src={extension} alt="" />

                                <div class="icon-content">
                                    <h5 class="text-uppercase">
                                        Home Extension</h5>
                                    <p>We prioritize safety with rigorous protocols, comprehensive training, and adherence to industry standards, ensuring a secure environment on every project.</p>
                                </div>
                                <Link to="/service-detail/home-extension" style={{ marginTop: "5px" }}>
                                    <button name="submit" type="submit" value="Submit" class="site-button  m-r15">Know More</button>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div class="spec_icon p-a20 left bg-white ">
                                <img src={loft} alt="" />
                                <div class="icon-content">
                                    <h5 class="text-uppercase">
                                        Loft Conversion</h5>
                                    <p>We foster community by supporting local initiatives, engaging with residents, and contributing to projects that enhance and uplift our surroundings.</p>
                                </div>
                                <Link to="/service-detail/loft-conversion" style={{ marginTop: "5px" }}>
                                    <button name="submit" type="submit" value="Submit" class="site-button  m-r15">Know More</button>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div class="spec_icon p-a20 left bg-white ">
                                <img src={build} alt="" />
                                <div class="icon-content">
                                    <h5 class="text-uppercase">
                                        New builds</h5>
                                    <p>We embrace sustainability by using eco-friendly materials, reducing waste, and implementing energy-efficient practices, ensuring a greener future for all.</p>
                                </div>
                                <Link to="/service-detail/new-builds" style={{ marginTop: "5px" }}>
                                    <button name="submit" type="submit" value="Submit" class="site-button  m-r15">Know More</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="p-t80 p-b50 bg-black latest_pro">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-sm-12">
                            <h2 class="text-uppercase text-white m-a0 p-t15" style={{ marginBottom: "30px" }}>Latest Projects</h2>
                        </div>
                        {/* <div className="col-lg-8 col-sm-12">
                            <div class="filter-wrap p-tb15 right">
                                <ul class="masonry-filter outline-style text-uppercase customize">
                                    <li class="active"><a data-filter="*" href="#"><span> All</span></a></li>
                                    <li class=""><a data-filter=".cat-1" href="#">House</a></li>
                                    <li class=""><a data-filter=".cat-2" href="#">Building</a></li>
                                    <li class=""><a data-filter=".cat-3" href="#">Office</a></li>
                                    <li class=""><a data-filter=".cat-4" href="#">Garden</a></li>
                                    <li class=""><a data-filter=".cat-5" href="#">Interior</a></li>
                                </ul>
                            </div>
                        </div> */}
                    </div>

                    <div className="row">
                        <div className="col-lg-3 col-sm-12">
                            <img src={g1} alt="" style={{ width: "100%" }} />
                        </div>
                        <div className="col-lg-3 col-sm-12">
                            <img src={g2} alt="" style={{ width: "100%" }} />
                        </div>
                        <div className="col-lg-3 col-sm-12">
                            <img src={g3} alt="" style={{ width: "100%" }} />
                        </div>
                        <div className="col-lg-3 col-sm-12">
                            <img src={g4} alt="" style={{ width: "100%" }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="p-t80 p-b50 section-full bg-gray">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-sm-12">
                            <h2 className="text-center">Why Choose us</h2>
                            <p className="text-center" style={{ color: "gray" }}>Choose us for exceptional craftsmanship, innovative designs, timely delivery, and a commitment to exceeding your construction expectations.</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-sm-12">

                            <div class="spec_icon p-a20 left bg-white ">
                                <IoIosFootball fontSize="30px" />

                                <div class="icon-content">
                                    <h5 class="text-uppercase">BEST QUALITY</h5>
                                    <p>We ensure the best quality through superior materials, expert craftsmanship, and rigorous standards, delivering excellence in every project.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">

                            <div class="spec_icon p-a20 left bg-white ">
                                <FaTrophy fontSize="30px" />

                                <div class="icon-content">
                                    <h5 class="text-uppercase">INTEGFRITY</h5>
                                    <p>We uphold integrity by being transparent, honest, and reliable in all our dealings, building trust with every client and project.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div class="spec_icon p-a20 left bg-white ">
                                <AiFillLike fontSize="30px" />

                                <div class="icon-content">
                                    <h5 class="text-uppercase">STRATEGY</h5>
                                    <p>Our strategy focuses on meticulous planning, innovative solutions, and effective execution to ensure successful project outcomes and client satisfaction.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div class="spec_icon p-a20 left bg-white ">
                                <AiOutlineSafety fontSize="30px" />

                                <div class="icon-content">
                                    <h5 class="text-uppercase">
                                        SAFETY</h5>
                                    <p>We prioritize safety with rigorous protocols, comprehensive training, and adherence to industry standards, ensuring a secure environment on every project.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div class="spec_icon p-a20 left bg-white ">
                                <FaPeopleGroup fontSize="30px" />

                                <div class="icon-content">
                                    <h5 class="text-uppercase">
                                        COMMUNITY</h5>
                                    <p>We foster community by supporting local initiatives, engaging with residents, and contributing to projects that enhance and uplift our surroundings.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div class="spec_icon p-a20 left bg-white ">
                                <CiSettings fontSize="30px" />

                                <div class="icon-content">
                                    <h5 class="text-uppercase">
                                        STASTAINABILITY</h5>
                                    <p>We embrace sustainability by using eco-friendly materials, reducing waste, and implementing energy-efficient practices, ensuring a greener future for all.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="p-t80 p-b50">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-sm-12">
                            <h2 className="text-center">Our team</h2>
                            <p className="text-center" style={{ color: "gray" }}>Our team consists of skilled professionals dedicated to delivering excellence, innovation, and exceptional results on every project.</p>
                        </div>

                        <div className="row">
                            <div className="col-lg-3 col-sm-12">
                                <div className="team_card">
                                    <img src={t1} alt="" style={{ width: "100%" }} />
                                    <div className="team_card_desc">
                                        <h4>Ramesh</h4>
                                        <p>Building Worker</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-12">
                                <div className="team_card">
                                    <img src={t2} alt="" style={{ width: "100%" }} />
                                    <div className="team_card_desc">
                                        <h4>Sanjay</h4>
                                        <p>Building Worker</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-12">
                                <div className="team_card">
                                    <img src={t3} alt="" style={{ width: "100%" }} />
                                    <div className="team_card_desc">
                                        <h4>Riya</h4>
                                        <p>Building Worker</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-12">
                                <div className="team_card">
                                    <img src={t4} alt="" style={{ width: "100%" }} />
                                    <div className="team_card_desc">
                                        <h4>Samay</h4>
                                        <p>Building Worker</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="p-t80 p-b50">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-sm-12">
                            <h2 className="text-center">Our Clients</h2>
                            <p className="text-center" style={{ color: "gray" }}>Our clients are valued partners, benefiting from our commitment to quality, reliability, and tailored solutions for their needs.</p>
                        </div>


                    </div>

                    <div className="row">
                        <Swiper
                            pagination={{
                                type: "fraction",
                            }}
                            breakpoints={{
                                320: { slidesPerView: 1, spaceBetween: 80 },
                                480: { slidesPerView: 1, spaceBetween: 150 },
                                768: { slidesPerView: 4, spaceBetween: 50 },
                                1024: { slidesPerView: 4, spaceBetween: 0 },
                            }}
                            slidesPerView={3}
                            navigation={true}
                            modules={[Pagination, Navigation]}
                            className="mySwiper"
                        >
                            <SwiperSlide><img src={logo3} alt="" style={{ width: "100%" }} /></SwiperSlide>
                            <SwiperSlide><img src={logo4} alt="" style={{ width: "100%" }} /></SwiperSlide>
                            <SwiperSlide><img src={logo3} alt="" style={{ width: "100%" }} /></SwiperSlide>
                            <SwiperSlide><img src={logo4} alt="" style={{ width: "100%" }} /></SwiperSlide>
                            <SwiperSlide><img src={logo3} alt="" style={{ width: "100%" }} /></SwiperSlide>

                        </Swiper>
                    </div>
                </div>
            </div>

            <div className="p-t80">
                <div class="call-to-action-wrap call-to-action-skew site-bg-primary bg-no-repeat" >
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-8 col-md-7">
                                <div class="call-to-action-left p-tb20">
                                    <h4 class="text-uppercase m-b10">We are ready to build your dream tell us more about your project</h4>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse viverra mauris eget tortor.</p>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-5">
                                <div class="call-to-action-right p-tb30">
                                    <a class="site-button-secondry  m-r15 text-uppercase font-weight-600">
                                        Contact us  <i class="fa fa-angle-double-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            {showContactModal &&

                <div className="modal_wrapper">
                    <div id="contactModal" className="contact_modal">

                        <div className="modal-content">
                            <span className="close" onClick={() => setShowContactModal(false)} style={{ color: "#000" }}>&times;</span>
                            <div className="contact__page_card">
                                <h4>Contact Form</h4>

                                <div class="p-a30 bg-gray">

                                    <form class="cons-contact-form" method="post" action="phpmailer/mail.php">

                                        <div class="row">

                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <span class="input-group-addon"><i class="fa fa-user"></i></span>
                                                        <input name="username" type="text" required="" class="form-control" placeholder="Name" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <span class="input-group-addon"><i class="fa fa-envelope"></i></span>
                                                        <input name="Phone" type="phone" class="form-control" required="" placeholder="Enter Phone No" />
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <span class="input-group-addon v-align-t"><i class="fa fa-pencil"></i></span>
                                                        <textarea name="message" rows="5" class="form-control " required="" placeholder="Message" columns="5"></textarea>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-12">
                                                <button name="submit" type="submit" value="Submit" class="site-button  m-r15">Submit  <i class="fa fa-angle-double-right"></i></button>

                                            </div>

                                        </div>

                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            }






        </>
    )
}

export default Home