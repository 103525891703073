import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import "./assets/styles/global.css"
import Footer from "./layout/Footer";
import About from "./pages/About";
import Gallery from "./pages/Gallery";
import Contact from "./pages/Contact";
import ServiceDetail from "./pages/ServiceDetail";
import Bedroom from "./pages/Bedroom";
import Brickwork from "./pages/Brickwork";
import HomeExtension from "./pages/HomeExtension";
import NewBuilds from "./pages/NewBuilds";
import LoftConversion from "./pages/LoftConversion";
import KitchenFitting from "./pages/projects/KitchenFitting";
import BrickWorks from "./pages/projects/BrickWorks";
import HomeRenovations from "./pages/projects/HomeRenovations";
import HomeRenovation from "./pages/HomeRenovation";
import HomeExtensions from "./pages/projects/HomeExtensions";
import LoftConversions from "./pages/projects/LoftConversions";
import NewBuild from "./pages/projects/NewBuild";
import Career from "./pages/Career";


const App = () => {
  return (
    <Router>

      <Routes>

        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/service-detail/bedroom-kitchen-fitting" element={<Bedroom />} />
        <Route path="/service-detail/brickwork-services" element={<Brickwork />} />
        <Route path="/service-detail/home-renovation" element={<HomeRenovation />} />
        <Route path="/service-detail/home-extension" element={<HomeExtension />} />
        <Route path="/service-detail/loft-conversion" element={<LoftConversion />} />
        <Route path="/service-detail/new-builds" element={<NewBuilds />} />
        <Route path="/project-detail/bedroom-kitchen-fitting" element={<KitchenFitting />} />
        <Route path="/project-detail/brickwork-services" element={<BrickWorks />} />
        <Route path="/project-detail/home-renovation" element={<HomeRenovations />} />
        <Route path="/project-detail/home-extension" element={<HomeExtensions />} />
        <Route path="/project-detail/loft-conversion" element={<LoftConversions />} />
        <Route path="/project-detail/new-builds" element={<NewBuild />} />
        <Route path="/career" element={<Career />} />
        <Route path="/contact-us" element={<Contact />} />
      </Routes>
      <Footer />
    </Router>
  )
}

export default App