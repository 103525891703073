import React from 'react'
import "../assets/styles/footer.css"
import { RiArrowRightDoubleLine } from 'react-icons/ri'
import { IoCallOutline, IoLocationOutline } from 'react-icons/io5'
import { IoIosPhonePortrait } from 'react-icons/io'
import { MdOutlineFax } from 'react-icons/md'
import { TfiEmail } from 'react-icons/tfi'
import { FaFacebookF, FaLinkedinIn, FaRegPaperPlane, FaServicestack } from 'react-icons/fa'
import { CiInstagram, CiTwitter } from 'react-icons/ci'
import { Link } from 'react-router-dom'
import logo_new_white from "../assets/images/logos/logo_new_white.png"


const Footer = () => {
    return (
        <div class="footer-top overlay-wraper">
            <div class="overlay-main"></div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-5 col-md-6">
                        <div class="widget widget_about">
                            <h4 class="widget-title">About Company</h4>
                            <div class="logo-footer clearfix p-b15">
                                <a href="/"><img src={logo_new_white} alt="image" /></a>
                            </div>
                            <p>Our company excels in delivering top-notch construction services, blending innovation with quality to create exceptional spaces and lasting client satisfaction.
                            </p>
                        </div>
                    </div>
                    {/* <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="widget recent-posts-entry-date">
                            <h4 class="widget-title">Resent Post</h4>
                            <div class="widget-post-bx">
                                <div class="bdr-light-blue widget-post clearfix  bdr-b-1 m-b10 p-b10">
                                    <div class="wt-post-date text-center text-uppercase text-white p-t5">
                                        <strong>20</strong>
                                        <span>Mar</span>
                                    </div>
                                    <div class="wt-post-info">
                                        <div class="wt-post-header">
                                            <h6 class="post-title"><a href="blog-single.html">Blog title first </a></h6>
                                        </div>
                                        <div class="wt-post-meta">
                                            <ul>
                                                <li class="post-author"><i class="fa fa-user"></i>By Admin</li>
                                                <li class="post-comment"><i class="fa fa-comments"></i> 28</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="bdr-light-blue widget-post clearfix  bdr-b-1 m-b10 p-b10">
                                    <div class="wt-post-date text-center text-uppercase text-white p-t5">
                                        <strong>30</strong>
                                        <span>Mar</span>
                                    </div>
                                    <div class="wt-post-info">
                                        <div class="wt-post-header">
                                            <h6 class="post-title"><a href="blog-single.html">Blog title first </a></h6>
                                        </div>
                                        <div class="wt-post-meta">
                                            <ul>
                                                <li class="post-author"><i class="fa fa-user"></i>By Admin</li>
                                                <li class="post-comment"><i class="fa fa-comments"></i> 29</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="bdr-light-blue widget-post clearfix  bdr-b-1 m-b10 p-b10">
                                    <div class="wt-post-date text-center text-uppercase text-white p-t5">
                                        <strong>31</strong>
                                        <span>Mar</span>
                                    </div>
                                    <div class="wt-post-info">
                                        <div class="wt-post-header">
                                            <h6 class="post-title"><a href="blog-single.html">Blog title first </a></h6>
                                        </div>
                                        <div class="wt-post-meta">
                                            <ul>
                                                <li class="post-author"><i class="fa fa-user"></i>By Admin</li>
                                                <li class="post-comment"><i class="fa fa-comments"></i> 30</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <div class="widget widget_services">
                            <h4 class="widget-title">Useful links</h4>
                            <ul>
                                <li><Link to="/"><RiArrowRightDoubleLine /> Home</Link></li>
                                <li><Link to="/about-us"><RiArrowRightDoubleLine /> About Us</Link></li>
                                <li><Link to="/gallery"><RiArrowRightDoubleLine /> Gallery</Link></li>
                                <li><Link to="/contact-us"><RiArrowRightDoubleLine />Contact Us</Link></li>

                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        {/* <div class="widget widget_newsletter">
                            <h4 class="widget-title">Newsletter</h4>
                            <div class="newsletter-bx">
                                <form role="search" method="post">
                                    <div class="input-group">
                                        <input name="news-letter" class="form-control" placeholder="ENTER YOUR EMAIL" type="text" />
                                        <span class="input-group-btn">
                                            <button type="submit" class="site-button"><FaRegPaperPlane style={{ color: "#fff" }} /></button>
                                        </span>
                                    </div>
                                </form>
                            </div>
                        </div> */}
                        <div class="widget widget_social_inks">
                            <h4 class="widget-title">Social Links</h4>
                            <ul class="social-icons social-square social-darkest">
                                <li><a href="javascript:void(0);" class="fa fa-facebook"><FaFacebookF /></a></li>
                                <li><a href="javascript:void(0);" class="fa fa-twitter"><CiTwitter /></a></li>
                                <li><a href="javascript:void(0);" class="fa fa-linkedin"><FaLinkedinIn /></a></li>
                                <li><a href="javascript:void(0);" class="fa fa-instagram"><CiInstagram /></a></li>
                            </ul>
                        </div>

                        <div className="widget">
                            <Link to="/service-detail/bedroom-kitchen-fitting">
                                <button name="submit" type="submit" value="Submit" class="site-button  m-r15">What We Do <FaServicestack /></button>
                            </Link>
                            <Link to="/contact-us">
                                <button name="submit" type="submit" value="Submit" class="site-button  m-r15">Contact Us <IoCallOutline /></button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div class="row">

                    <div class="col-lg-4 col-md-6 col-sm-12 p-tb20">
                        <div class="wt-icon-box-wraper left  bdr-1 bdr-gray-dark p-tb15 p-lr10 clearfix">
                            <div class="icon-md site-text-primary">
                                <span class="iconmoon-travel"><IoLocationOutline fontSize="50px" color='#ff7404' /></span>
                            </div>
                            <div class="icon-content">
                                <h5 class="wt-tilte text-uppercase m-b0">Address</h5>
                                <p>8 Trevor road HA80eu ,Edgware
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-12 p-tb20 ">
                        <div class="wt-icon-box-wraper left  bdr-1 bdr-gray-dark p-tb15 p-lr10 clearfix ">
                            <div class="icon-md site-text-primary">
                                <span class="iconmoon-smartphone-1"><IoIosPhonePortrait fontSize="50px" color='#ff7404' /></span>
                            </div>
                            <div class="icon-content">
                                <h5 class="wt-tilte text-uppercase m-b0">Phone</h5>
                                <p class="m-b0"> +447928470998</p>
                            </div>
                        </div>
                    </div>



                    <div class="col-lg-4 col-md-6 col-sm-12 p-tb20">
                        <div class="wt-icon-box-wraper left  bdr-1 bdr-gray-dark p-tb15 p-lr10 clearfix">
                            <div class="icon-md site-text-primary">
                                <span class="iconmoon-email"><TfiEmail fontSize="50px" color='#ff7404' /></span>
                            </div>
                            <div class="icon-content">
                                <h5 class="wt-tilte text-uppercase m-b0">Email</h5>
                                <p class="m-b0">info@sajinaconstruction.co.uk</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer