import React, { useEffect, useState } from 'react'
import Navbar from '../../layout/Navbar'
import ResponsiveNavbar from '../../layout/ResponsiveNavbar';
import loft_conversion_1 from "../../assets/images/projects/loft_conversion_1.webp";
import loft_conversion_2 from "../../assets/images/projects/loft_conversion_2.webp"
import loft_conversion_3 from "../../assets/images/projects/loft_conversion_3.webp"
import loft_conversion_4 from "../../assets/images/projects/loft_conversion_4.webp"
import { FaExpandArrowsAlt } from 'react-icons/fa';

const NewBuild = () => {
    const [modalImage, setModalImage] = useState("")
    const [currentIndex, setCurrentIndex] = useState("")


    const galleryImages = [
        {
            img: loft_conversion_1
        },
        {
            img: loft_conversion_2
        },
        {
            img: loft_conversion_3
        },
        {
            img: loft_conversion_4
        }
    ]


    const openModal = (index) => {
        setModalImage(galleryImages[index].img);
        console.log(modalImage)
        setCurrentIndex(index)
    }


    const closeModal = () => {
        setModalImage("");
    };


    const prevImage = () => {
        if (currentIndex === 0) {
            // If current index is the first image, wrap around to the last image
            const lastIndex = galleryImages.length - 1;
            setModalImage(galleryImages[lastIndex].img);
            setCurrentIndex(lastIndex);
        } else {
            // Otherwise, go to the previous image
            const newIndex = currentIndex - 1;
            setModalImage(galleryImages[newIndex].img);
            setCurrentIndex(newIndex);
        }
    }


    const nextImage = () => {
        if (currentIndex === galleryImages.length - 1) {
            // If current index is the last image, wrap around to the first image
            setModalImage(galleryImages[0].img);
            setCurrentIndex(0);
        } else {
            // Otherwise, go to the next image
            const newIndex = currentIndex + 1;
            setModalImage(galleryImages[newIndex].img);
            setCurrentIndex(newIndex);
        }

    }

    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    return (
        <>
            <Navbar />
            <ResponsiveNavbar />

            <div className="gallery_banner new_build">
                <div class="overlay-main bg-black opacity-07"></div>
                <div className="content">
                    <h1>New Builds</h1>
                </div>
            </div>


            <div className="about_sec p-t80 p-b50">
                <div className="container">
                    <h2 className="text-center">Our Projects</h2>
                    <div className="row">
                        {
                            galleryImages.map((item, index) => {
                                return (
                                    <div className="col-lg-4 col-sm-12">
                                        <div className="gallery_card">
                                            <img src={item.img} alt="" srcset="" style={{ width: "100%" }} />

                                            <div className="gallery_icon" onClick={() => openModal(index)}>
                                                <div>
                                                    <FaExpandArrowsAlt />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                )
                            })
                        }
                    </div>


                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-8 col-sm-12">
                            {modalImage && <div id="imageModal" class="imgmodal">
                                <span class="close" onClick={closeModal}>&times;</span>
                                <img class="modal-content" src={modalImage} id="modalImage" />
                                <div class="caption" id="caption"></div>
                                <button class="prev" onclick={prevImage}>&#10094; Prev</button>
                                <button class="next" onclick={nextImage}>Next &#10095;</button>
                            </div>}
                        </div>
                    </div>

                </div>
            </div>


        </>
    )
}

export default NewBuild