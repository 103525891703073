import React, { useState } from 'react'
import Navbar from '../layout/Navbar'
import ResponsiveNavbar from '../layout/ResponsiveNavbar'
import { FiPlus } from 'react-icons/fi';
import { RiArrowRightDoubleLine } from 'react-icons/ri';
import "../assets/styles/carrer.css"

const Career = () => {
    const [openPanel, setOpenPanel] = useState('collapseOne5');

    const togglePanel = (panelId) => {
        setOpenPanel(openPanel === panelId ? '' : panelId);
    };
    return (
        <>

            <Navbar />
            <ResponsiveNavbar />

            <div className="gallery_banner main_gallery">
                <div class="overlay-main bg-black opacity-07"></div>
                <div className="content">
                    <h1>Career</h1>
                </div>
            </div>
            <div className="about_sec p-t80 p-b50">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                            <div className="career_left">
                                <h4 class="widget-title" style={{ color: "#000" }}>Default Accordion Space</h4>

                                <div class="section-content p-b30">
                                    <div id="accordion5">
                                        <div className="panel wt-panel">
                                            <div className="acod-head acc-actives">
                                                <h6 className="acod-title text-uppercase">
                                                    <a
                                                        href="javascript:;"
                                                        onClick={() => togglePanel('collapseOne5')}
                                                    >
                                                        <i className="fa fa-globe"></i> Design and Planning
                                                        <span className="indicator">
                                                            <FiPlus />
                                                        </span>
                                                    </a>
                                                </h6>
                                            </div>
                                            <div
                                                id="collapseOne5"
                                                className={`acod-body collapse ${openPanel === 'collapseOne5' ? 'show' : ''}`}
                                            >
                                                <div className="acod-content p-tb15">
                                                    Initial consultations, design development, and detailed planning, including 3D renderings and layout adjustments to align with your vision.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="panel wt-panel">
                                            <div className="acod-head">
                                                <h6 className="acod-title text-uppercase">
                                                    <a
                                                        href="javascript:;"
                                                        onClick={() => togglePanel('collapseTwo5')}
                                                        className={openPanel === 'collapseTwo5' ? '' : 'collapsed'}
                                                    >
                                                        <i className="fa fa-photo"></i> Material Selection
                                                        <span className="indicator">
                                                            <FiPlus />
                                                        </span>
                                                    </a>
                                                </h6>
                                            </div>
                                            <div
                                                id="collapseTwo5"
                                                className={`acod-body collapse ${openPanel === 'collapseTwo5' ? 'show' : ''}`}
                                            >
                                                <div className="acod-content p-tb15">
                                                    Assistance in choosing high-quality materials, such as flooring, cabinetry, fixtures, and finishes, that match your style and ensure durability.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="panel wt-panel">
                                            <div className="acod-head">
                                                <h6 className="acod-title text-uppercase">
                                                    <a
                                                        href="javascript:;"
                                                        onClick={() => togglePanel('collapseThree5')}
                                                        className={openPanel === 'collapseThree5' ? '' : 'collapsed'}
                                                    >
                                                        <i className="fa fa-cog"></i> Construction and Installation
                                                        <span className="indicator">
                                                            <FiPlus />
                                                        </span>
                                                    </a>
                                                </h6>
                                            </div>
                                            <div
                                                id="collapseThree5"
                                                className={`acod-body collapse ${openPanel === 'collapseThree5' ? 'show' : ''}`}
                                            >
                                                <div className="acod-content p-tb15">
                                                    Comprehensive construction services, including demolition, structural changes, electrical and plumbing updates, and installation of new elements like kitchens, bathrooms, and living spaces.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <div className="career_right">
                                <h4 class="widget-title" style={{ color: "#000" }}>Responsibilities</h4>

                                <ul className='career_right_list'>
                                    <li><RiArrowRightDoubleLine />Arranging client coordination</li>
                                    <li><RiArrowRightDoubleLine />Analyzing Data and understanding results
                                    </li>
                                    <li><RiArrowRightDoubleLine />Professional, able to interact with vendors/clients;</li>
                                    <li><RiArrowRightDoubleLine />Great interpersonal communication skills
                                    </li>
                                    <li><RiArrowRightDoubleLine />
                                        Providing legal and scholarly research;

                                    </li>
                                    <li><RiArrowRightDoubleLine />
                                        Assisting senior consultants
                                    </li>
                                    <li><RiArrowRightDoubleLine />
                                        Arranging client coordination
                                    </li>
                                    <li><RiArrowRightDoubleLine />
                                        Analyzing Data and understanding results
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="contact_section_career">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                            <div className="contact__page_card">
                                <h4>Contact Form</h4>

                                <div class="p-a30 bg-gray">

                                    <form class="cons-contact-form" method="post" action="phpmailer/mail.php">

                                        <div class="row">

                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <span class="input-group-addon"><i class="fa fa-user"></i></span>
                                                        <input name="username" type="text" required="" class="form-control" placeholder="Name" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <span class="input-group-addon"><i class="fa fa-envelope"></i></span>
                                                        <input name="phone" type="text" class="form-control" required="" placeholder="Phone" />
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <span class="input-group-addon"><i class="fa fa-envelope"></i></span>
                                                        <input name="email" type="text" class="form-control" required="" placeholder="Email" />
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <span class="input-group-addon v-align-t"><i class="fa fa-pencil"></i></span>
                                                        <textarea name="message" rows="1" class="form-control " required="" placeholder="Message"></textarea>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-12">
                                                <button name="submit" type="submit" value="Submit" class="site-button  m-r15">Submit  <i class="fa fa-angle-double-right"></i></button>
                                                <button name="Resat" type="reset" value="Reset" class="site-button ">Reset  <i class="fa fa-angle-double-right"></i></button>
                                            </div>

                                        </div>

                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Career