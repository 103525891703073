import React, { useEffect } from 'react'
import "../assets/styles/contact.css"
import { IoIosPhonePortrait } from 'react-icons/io'
import { HiOutlineMailOpen } from 'react-icons/hi'
import { IoLocationSharp } from 'react-icons/io5'
import Navbar from '../layout/Navbar'
import ResponsiveNavbar from '../layout/ResponsiveNavbar'

const Contact = () => {

    useEffect(() => {
        window.scroll(0, 0)
    }, [])


    return (
        <>

            <Navbar />
            <ResponsiveNavbar />
            <section className="contact_section">
                <div className="content">
                    <h1>Contact Us</h1>
                </div>
            </section>


            <div className="about_sec p-t80 p-b50">
                <div className="container">

                    <div className="row">
                        <div className="col-lg-4 col-sm-12">
                            <div className="contact_card">
                                <IoIosPhonePortrait fontSize="30px" />
                                <p>Phone Number</p>
                                <p> +447928470998</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-12">
                            <div className="contact_card">
                                <HiOutlineMailOpen fontSize="30px" />
                                <p>
                                    Email address</p>
                                <p>info@sajinaconstruction.co.uk</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-12">
                            <div className="contact_card">
                                <IoLocationSharp fontSize="30px" />
                                <p>Address info</p>
                                <p>8 Trevor road HA80eu ,Edgware</p>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

            <div className="about_sec p-t80 p-b50">
                <div className="container">
                    <div className="row">
                        <div className='col-lg-6 col-sm-12'>
                            <div className="location_card">
                                <h4 class="text-uppercase">Location</h4>


                                <div className="location_sec">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4966.020603260364!2d-0.132262!3d51.513027!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487604d31cdfefbb%3A0x27d5339f1859d7f1!2s62%20Dean%20St%2C%20London%20W1D%204QF%2C%20UK!5e0!3m2!1sen!2sin!4v1726312306336!5m2!1sen!2sin" width="100%" height="300px" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-12'>
                            <div className="contact__page_card">
                                <h4>Contact Form</h4>

                                <div class="p-a30 bg-gray">

                                    <form class="cons-contact-form" method="post" action="phpmailer/mail.php">

                                        <div class="row">

                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <span class="input-group-addon"><i class="fa fa-user"></i></span>
                                                        <input name="username" type="text" required="" class="form-control" placeholder="Name" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <span class="input-group-addon"><i class="fa fa-envelope"></i></span>
                                                        <input name="email" type="text" class="form-control" required="" placeholder="Email" />
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <span class="input-group-addon v-align-t"><i class="fa fa-pencil"></i></span>
                                                        <textarea name="message" rows="1" class="form-control " required="" placeholder="Message"></textarea>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-12">
                                                <button name="submit" type="submit" value="Submit" class="site-button  m-r15">Submit  <i class="fa fa-angle-double-right"></i></button>
                                                <button name="Resat" type="reset" value="Reset" class="site-button ">Reset  <i class="fa fa-angle-double-right"></i></button>
                                            </div>

                                        </div>

                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="p-t80">
                <div class="call-to-action-wrap call-to-action-skew site-bg-primary bg-no-repeat" >
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-8 col-md-7">
                                <div class="call-to-action-left p-tb20">
                                    <h4 class="text-uppercase m-b10">We are ready to build your dream tell us more about your project</h4>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse viverra mauris eget tortor.</p>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-5">
                                <div class="call-to-action-right p-tb30">
                                    <a class="site-button-secondry  m-r15 text-uppercase font-weight-600">
                                        Contact us  <i class="fa fa-angle-double-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact