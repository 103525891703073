import React, { useEffect, useState } from 'react'
import "../assets/styles/service.css"
import { IoLocationOutline, IoMailUnreadOutline } from 'react-icons/io5'
import { FaPhoneAlt, FaWhatsapp } from 'react-icons/fa'
import { IoMdCall, IoMdMail } from 'react-icons/io'

import { Autoplay, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react';
import Navbar from '../layout/Navbar'
import ResponsiveNavbar from '../layout/ResponsiveNavbar'
import { FiPlus } from 'react-icons/fi'
import kitchen_fitting from "../assets/images/projects/kitchen_fitting.webp"
import kitchen_fiting_2 from "../assets/images/projects/kitchen_fiting_2.webp"
import kitchen_fiting_3 from "../assets/images/projects/kitchen_fiting_3.webp"
import { Link } from 'react-router-dom'
import { MdContacts } from 'react-icons/md'
import FixedFooter from '../layout/FixedFooter'

const Bedroom = () => {
    const [openPanel, setOpenPanel] = useState('collapseOne5');

    const togglePanel = (panelId) => {
        setOpenPanel(openPanel === panelId ? '' : panelId);
    };

    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    return (
        <>
            <Navbar />
            <ResponsiveNavbar />
            <div class="service_sec_bedroom">
                <div class="overlay-main_service"></div>
                <div class="container">
                    <div class="wt-bnr-inr-entry">
                        <h1>Bedroom & Kitchen fitting</h1>
                    </div>
                </div>
            </div>

            <div className="about_sec p-t80 p-b50">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-sm-12">

                            <div class="wt-nav-block m-b30">
                                <h2 class="wt-nav-title">What we do</h2>
                                <ul class="wt-nav wt-nav-pills">
                                    <li><Link to="/service-detail/bedroom-kitchen-fitting" >Bedroom & Kitchen fitting</Link></li>
                                    <li><Link to="/service-detail/brickwork-services">Brickwork Services</Link></li>
                                    <li><Link to="/service-detail/home-renovation">Home Renovation</Link></li>
                                    <li><Link to="/service-detail/home-extension">House Extension</Link></li>
                                    <li><Link to="/service-detail/loft-conversion">Loft Conversion</Link></li>
                                    <li><Link to="/service-detail/new-builds">New Builds</Link></li>
                                </ul>
                            </div>

                            <div className="widget widget_getintuch">
                                <h4 class="widget-title" style={{ color: "#000" }}>Contact us</h4>

                                <ul>
                                    <li><IoLocationOutline style={{ color: "#fff" }} /><strong>Address</strong> <p>8 Trevor road HA80eu ,Edgware</p></li>
                                    <li><FaPhoneAlt style={{ color: "#fff" }} /><strong>phone</strong>
                                        <p>+447928470998</p>
                                    </li>
                                    <li><IoMdMail style={{ color: "#fff" }} /><strong>email</strong>
                                        <p>info@sajinaconstruction.co.uk</p>
                                    </li>
                                </ul>
                            </div>


                            <div className="contact__page_card  fixed_form" style={{ margin: "0px" }}>
                                <h4>Enquiry Form</h4>

                                <div class="p-a30 bg-gray">

                                    <form class="cons-contact-form">

                                        <div class="row">

                                            <div class="col-md-12 col-lg-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <span class="input-group-addon"><i class="fa fa-user"></i></span>
                                                        <input name="username" type="text" required="" class="form-control" placeholder="Name" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-12 col-lg-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <span class="input-group-addon"><i class="fa fa-envelope"></i></span>
                                                        <input name="email" type="text" class="form-control" required="" placeholder="Email" />
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="col-md-12 col-lg-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <span class="input-group-addon v-align-t"><i class="fa fa-pencil"></i></span>
                                                        <textarea name="message" rows="1" class="form-control " required="" placeholder="Message"></textarea>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-12 col-lg-12">
                                                <button name="submit" type="submit" value="Submit" class="site-button  m-r15">Submit  <i class="fa fa-angle-double-right"></i></button>
                                                <button name="Resat" type="reset" value="Reset" class="site-button ">Reset  <i class="fa fa-angle-double-right"></i></button>
                                            </div>

                                        </div>

                                    </form>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 col-sm-12">
                            <div className="services_sect_card">
                                <Swiper
                                    spaceBetween={30}
                                    centeredSlides={true}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    navigation={true}
                                    modules={[Autoplay, Pagination, Navigation]}
                                    className="mySwiper"
                                >
                                    <SwiperSlide>
                                        <img src={kitchen_fitting} alt="" style={{ width: "100%" }} />
                                    </SwiperSlide>
                                    <SwiperSlide>  <img src={kitchen_fiting_2} alt="" style={{ width: "100%" }} /></SwiperSlide>
                                    <SwiperSlide>  <img src={kitchen_fiting_3} alt="" style={{ width: "100%" }} /></SwiperSlide>

                                </Swiper>
                            </div>
                            <div class="wt-box">
                                <h2>Bedroom & Kitchen fitting</h2>
                                <p>
                                    At Sajina Constructions, we understand that the bedroom and kitchen are two of the most essential spaces in your home, each requiring meticulous attention to detail and craftsmanship.
                                </p>

                                <p>
                                    Bedroom Fitting: We specialize in creating serene and functional bedroom environments tailored to your personal style and comfort. From custom wardrobes and storage solutions to elegant bed frames and bedside tables, our designs are crafted to maximize space and enhance your relaxation. Our team works closely with you to understand your vision and preferences, ensuring that every element, from the layout to the finishing touches, aligns with your dream bedroom.
                                </p>
                                <p>Kitchen Fitting: The kitchen is the heart of the home, and our approach is to combine functionality with aesthetic appeal. We offer a wide range of kitchen fitting services, including custom cabinetry, countertops, and backsplash designs. Our goal is to create a space that not only looks stunning but also meets your cooking and storage needs. We use high-quality materials and the latest techniques to ensure durability and style.</p>
                            </div>

                            <div class="wt-box">
                                <div class="p-t30">
                                    <div class="section-head">

                                        <h4 class="widget-title" style={{ color: "#000" }}>What is included</h4>
                                    </div>

                                    <div class="section-content p-b30">
                                        <div id="accordion5">
                                            <div className="panel wt-panel">
                                                <div className="acod-head acc-actives">
                                                    <h6 className="acod-title text-uppercase">
                                                        <a
                                                            href="javascript:;"
                                                            onClick={() => togglePanel('collapseOne5')}
                                                        >
                                                            <i className="fa fa-globe"></i> Custom Design
                                                            <span className="indicator">
                                                                <FiPlus />
                                                            </span>
                                                        </a>
                                                    </h6>
                                                </div>
                                                <div
                                                    id="collapseOne5"
                                                    className={`acod-body collapse ${openPanel === 'collapseOne5' ? 'show' : ''}`}
                                                >
                                                    <div className="acod-content p-tb15">
                                                        We design kitchens tailored to your cooking habits and style preferences, featuring custom cabinetry, countertops, and functional layouts. Our approach ensures that the kitchen is both practical and visually appealing.
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="panel wt-panel">
                                                <div className="acod-head">
                                                    <h6 className="acod-title text-uppercase">
                                                        <a
                                                            href="javascript:;"
                                                            onClick={() => togglePanel('collapseTwo5')}
                                                            className={openPanel === 'collapseTwo5' ? '' : 'collapsed'}
                                                        >
                                                            <i className="fa fa-photo"></i> Quality Materials
                                                            <span className="indicator">
                                                                <FiPlus />
                                                            </span>
                                                        </a>
                                                    </h6>
                                                </div>
                                                <div
                                                    id="collapseTwo5"
                                                    className={`acod-body collapse ${openPanel === 'collapseTwo5' ? 'show' : ''}`}
                                                >
                                                    <div className="acod-content p-tb15">
                                                        Utilizing high-grade materials, we ensure that every element of your kitchen, from countertops to cabinetry, is both durable and stylish. Our selection of materials enhances both the functionality and the aesthetic of your space.
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="panel wt-panel">
                                                <div className="acod-head">
                                                    <h6 className="acod-title text-uppercase">
                                                        <a
                                                            href="javascript:;"
                                                            onClick={() => togglePanel('collapseThree5')}
                                                            className={openPanel === 'collapseThree5' ? '' : 'collapsed'}
                                                        >
                                                            <i className="fa fa-cog"></i> Professional Installation
                                                            <span className="indicator">
                                                                <FiPlus />
                                                            </span>
                                                        </a>
                                                    </h6>
                                                </div>
                                                <div
                                                    id="collapseThree5"
                                                    className={`acod-body collapse ${openPanel === 'collapseThree5' ? 'show' : ''}`}
                                                >
                                                    <div className="acod-content p-tb15">
                                                        Our expert installers ensure precise fitting and finishing of all kitchen components. We focus on attention to detail, from alignment to functionality, ensuring that your kitchen is both beautiful and efficient.heets containing lorem Ipsum passagese.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

            <FixedFooter />

        </>
    )
}

export default Bedroom