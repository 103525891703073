import React, { useState } from 'react'
import { GiHamburgerMenu } from 'react-icons/gi';
import { Link } from 'react-router-dom';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { FiMinus } from 'react-icons/fi';
import { GoPlus } from 'react-icons/go';
import "../assets/styles/responsivenavbar.css"
import logo_new_colored from "../assets/images/logos/logo_new_colored.png";

const ResponsiveNavbar = () => {

    const [resposiveMenu, setResponsiveMenu] = useState(false);
    const [servicesMenu, setServicesMenu] = useState(null);

    return (
        <div className="responsive_navbar">
            <div className="container">
                <nav className="row align-items-center">
                    <div className="col-6">
                        <div className="navbar_left">
                            <div className="logo_area">
                                <Link to="/">
                                    <img src={logo_new_colored} alt="" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="navbar_right">
                            <GiHamburgerMenu
                                fontSize="20px"
                                onClick={() => setResponsiveMenu(!resposiveMenu)}
                                style={{ color: "#000" }}
                            />
                        </div>
                    </div>
                </nav>

                {resposiveMenu && (
                    <div className="responsive_menu_wrapper animate__animated animate__fadeInDown">
                        <div className="close_btn">
                            <IoMdCloseCircleOutline
                                fontSize="30px"
                                onClick={() => setResponsiveMenu(!resposiveMenu)}
                            />
                        </div>

                        <div className="resp_menu_list">
                            <ul className="resp_menu_items">
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <Link to="/about-us">About US</Link>
                                </li>
                                <li
                                    onClick={() =>
                                        setServicesMenu(
                                            servicesMenu === "services" ? null : "services"
                                        )
                                    }
                                >
                                    <Link>Our Services</Link>
                                    {servicesMenu === "services" ? <FiMinus /> : <GoPlus />}
                                </li>

                                {servicesMenu === "services" && (
                                    <ul className="responsive_submenu">
                                        <li>
                                            <Link to="/service-detail/bedroom-kitchen-fitting" >
                                                Bedroom & Kitchen Fitting
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/service-detail/brickwork-services">
                                                Brickwork Services
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/service-detail/home-renovation">
                                                Home Renovations
                                            </Link>
                                        </li>

                                        <li>
                                            <Link to="/service-detail/home-extension">
                                                Home Extension
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/service-detail/loft-conversion">
                                                Loft Conversion
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/service-detail/new-builds">
                                                New Builds
                                            </Link>
                                        </li>
                                    </ul>
                                )}


                                <li
                                    onClick={() =>
                                        setServicesMenu(
                                            servicesMenu === "project" ? null : "project"
                                        )
                                    }
                                >
                                    <Link>Our Project</Link>
                                    {servicesMenu === "project" ? <FiMinus /> : <GoPlus />}
                                </li>

                                {servicesMenu === "project" && (
                                    <ul className="responsive_submenu">
                                        <li>
                                            <Link to="/project-detail/bedroom-kitchen-fitting" >
                                                Bedroom & Kitchen Fitting
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/project-detail/brickwork-services" >
                                                Brickwork Services
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/project-detail/home-renovation">
                                                Home Renovations
                                            </Link>
                                        </li>

                                        <li>
                                            <Link to="/project-detail/home-extension">
                                                Home Extension
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/project-detail/loft-conversion">
                                                Loft Conversion
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/project-detail/new-builds">
                                                New Builds
                                            </Link>
                                        </li>
                                    </ul>
                                )}

                                <li>
                                    <Link to="/career">Career</Link>
                                </li>
                                <li>
                                    <Link to="/gallery">Gallery</Link>
                                </li>
                                <li>
                                    <Link to="/contact-us">Contact Us</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ResponsiveNavbar