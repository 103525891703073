import React, { useEffect, useState } from 'react'
import "../assets/styles/navbar.css"
import { MdOutlineArrowDropDownCircle } from 'react-icons/md'
import { Link, useLocation } from 'react-router-dom'

import logo_new_colored from "../assets/images/logos/logo_new_colored.png";
import logo_new_white from "../assets/images/logos/logo_new_white.png"

const Navbar = () => {

    const [stickyMenu, setStickyMenu] = useState(false)
    const [isMenuVisible, setMenuVisible] = useState(false);
    const [isHovered, setHovered] = useState(false);
    const [selectedMenu, setSelectedMenu] = useState("")


    const handleLinkClick = () => {
        setMenuVisible(false);
    };

    const handleMouseEnter = (data) => {
        setHovered(true);
        setMenuVisible(true);
        setSelectedMenu(data)
    };

    const handleMouseLeave = () => {
        setHovered(false);
        setMenuVisible(false);
        setSelectedMenu('')
    };

    useEffect(() => {

        const handleScroll = () => {
            if (window.scrollY > 100) {
                setStickyMenu(true)
            } else {
                setStickyMenu(false)
            }
        }

        window.addEventListener('scroll', handleScroll)

        return (() => {
            window.removeEventListener('scroll', handleScroll)
        })

    }, [])

    const location = useLocation();

    // Check if the current path is the homepage
    const isHomePage = location.pathname === '/';

    return (
        <div className={stickyMenu ? "sticky_header is_fixed" : (isHomePage ? "sticky_header" : "relative_header")}>
            <div >
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-3">
                            <div className="nav_logo">

                                <Link to="/">
                                    <img src={logo_new_white} alt="" className='dark_bg' />

                                </Link>
                                <Link to="/">
                                    <img src={logo_new_colored} alt="" className='wht_bg' />
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <ul className='nav_menu'>
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/about-us">About Us </Link></li>




                                <li
                                    className="submenu"
                                    onMouseEnter={() => handleMouseEnter("our-project")}


                                >
                                    Our Services <MdOutlineArrowDropDownCircle />
                                    {isMenuVisible && selectedMenu === "our-project" &&
                                        <ul className="submenu__list" onMouseLeave={handleMouseLeave}>
                                            <li>
                                                <Link to="/service-detail/bedroom-kitchen-fitting" >
                                                    Bedroom & Kitchen Fitting
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/service-detail/brickwork-services">
                                                    Brickwork Services
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/service-detail/home-renovation">
                                                    Home Renovations
                                                </Link>
                                            </li>

                                            <li>
                                                <Link to="/service-detail/home-extension">
                                                    Home Extension
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/service-detail/loft-conversion">
                                                    Loft Conversion
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/service-detail/new-builds">
                                                    New Builds
                                                </Link>
                                            </li>
                                        </ul>}

                                </li>

                                <li
                                    className="submenu"
                                    onMouseEnter={() => handleMouseEnter("our-services")}

                                >
                                    Our Projects <MdOutlineArrowDropDownCircle />
                                    {isMenuVisible && selectedMenu === "our-services" &&
                                        <ul className="submenu__list" onMouseLeave={handleMouseLeave}>
                                            <li>
                                                <Link to="/project-detail/bedroom-kitchen-fitting" >
                                                    Bedroom & Kitchen Fitting
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/project-detail/brickwork-services" >
                                                    Brickwork Services
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/project-detail/home-renovation">
                                                    Home Renovations
                                                </Link>
                                            </li>

                                            <li>
                                                <Link to="/project-detail/home-extension">
                                                    Home Extension
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/project-detail/loft-conversion">
                                                    Loft Conversion
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/project-detail/new-builds">
                                                    New Builds
                                                </Link>
                                            </li>
                                        </ul>}

                                </li>


                                <li><Link to="/career">Career</Link></li>
                                <li><Link to="/gallery">Gallery</Link></li>
                                <li><Link to="/contact-us">Contact Us</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default Navbar